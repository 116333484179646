
import Wysiwyg from '@/mixins/wysiwyg'
export default {
  name: 'UiStyleGuide',
  mixins: [
    Wysiwyg
  ],
  mounted() {
    const preElements = this.$el.getElementsByClassName('autopopulate-pre')
    Array.prototype.forEach.call(preElements, function(el) {
      if (el.nextElementSibling.nextElementSibling.tagName === 'PRE') {
        // const html = hljs.highlight(el.nextElementSibling.innerHTML, {language: 'html'}).value
        // console.log({value: hljs.highlight(el.nextElementSibling.innerHTML, {language: 'html'}), orig: el.nextElementSibling.innerHTML, all: hljs.highlight(el.nextElementSibling.innerHTML, {language: 'html'})})
        el.nextElementSibling.nextElementSibling.getElementsByTagName('code')[0].innerHTML = el.nextElementSibling.innerHTML
        hljs.highlightElement(el.nextElementSibling.nextElementSibling)
      }
    })
    this.$el.querySelectorAll('pre code').forEach((el) => {
      console.log(el)
    })
  }
}
