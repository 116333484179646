
import unescape from 'lodash/unescape'
import Post from '@/mixins/blog/post'

export default {
  name: 'UiBlogListPostsItem',
  mixins: [
    Post
  ],
  props: {
    post: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    animationDelayClass (index) {
      const classes = []
      if (index > 2) {
        classes.push('has-anim')
        if (index % 3 === 2) {
          classes.push('anim-delay-3')
        } else if (index % 2 === 1) {
          classes.push('anim-delay-2')
        } else {
          classes.push('anim-delay-1')
        }
      }
      return classes.join(' ')
    },
    unescape (content) {
      return unescape(content)
    }
  }
}
