
import unescape from 'lodash/unescape'

export default {
  name: 'UiTextSection',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      if (this?.overlapTop) {
        classes.push('overlap-top')
      }
      if (this?.overlapBottom) {
        classes.push('overlap-bottom')
      }
      if (!this?.preload) {
        classes.push('has-anim fade')
      }
      if (this.hideOnMobile) {
        classes.push('d-none d-md-block')
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    cleanTitle () {
      return unescape(this.title)
    },
    cleanContent () {
      return this.content
    }
  }
}
