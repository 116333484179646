
import UiBlogListPostsItem from '@/components/ui/blog/ListPosts/Item'
import Animation from '@/mixins/animation'

export default {
  name: 'UiBlogListPosts',
  components: {
    UiBlogListPostsItem
  },
  mixins: [Animation],
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
