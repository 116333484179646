
export default {
  name: 'UiAnimatedTextGrid',
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    pretitle: {
      type: String,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
  },
  computed: {
    classes () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemClasses (item, index, length) {
      const classes = []
      if (index > 0) {
        classes.push('anim-delay-' + (index+1))
      }
      // if (length === 5) {
      //
      // }
      if (length % 4 === 0) {
        classes.push('col-lg-3 col-sm-6')
      }
      if (length % 3 === 0) {
        classes.push('col-lg-4 col-sm-6')
      }
      return classes
    }
  }
}
