
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderFullWidthImage',
  components: {
    LazyHydrate,
    UiFullWidthImage: () => import('@/components/ui/sections/FullWidthImage')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  data () {
    return {
      triggerHydration: false
    }
  },
  created() {
    this.triggerHydration = !!this.section?.preload || this.number < 2
  }
}
