
import get from 'lodash/get'
import upperFirst from 'lodash/upperFirst'
import UiForm from '@/components/ui/form/Form'
import requestMutation from '@/graphql/query/requestMutation.gql'

export default{
  name: 'BuilderFormRequest',
  components: {
    UiForm
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  data () {
    return {
      mutation: requestMutation
    }
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.section?.styles?.no_section) {
        classes.push('section')
      }
      if (this.section?.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.section?.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this.section?.styles?.no_container ? '' : 'container'
    },
    fields () {
      let fields = []
      if (this.section?.request_form?.form) {
        const sectionFields = this.section.request_form.form.map((field) => {
          if (field?.__typename) {
            field.type = field.__typename.replace('ComponentForm', '').toLowerCase()
          } else if (field?.__component) {
            field.type = field?.__component.replace('form.', '').toLowerCase()
          }
          return field
        })
        fields = fields.concat(sectionFields)
      }
      fields.push({
        name: 'consent',
        type: 'checkbox',
        required: true,
        value: false,
        fullWidth: true,
        placeholder: 'By using this form you agree with the storage and handling of your data by this website.'
      })
      return fields
    }
  },
  methods: {
    formDataTransformer (data) {
      const newMappedData = {}
      this.fields.forEach(function(field) {
        if (field.name === 'consent') {
          return
        }
        const value = get(data, field.name)
        if (typeof value === 'undefined') {
          return
        }
        if (field.type === 'file') {
          return
        }
        newMappedData[field.name] = {
          name: field.name,
          value,
          '__typename': 'ComponentRequestItem' + upperFirst(field.type)
        }
      })
      return { data: newMappedData }
    },
    fileIdsByFieldTransformer (data) {
      const newMappedData = {}
      this.fields.forEach(function(field) {
        const value = get(data, field.name)
        if (typeof value === 'undefined') {
          return
        }
        if (field.type !== 'file') {
          return
        }
        newMappedData[field.name] = {
          name: field.name,
          value,
          '__typename': 'ComponentRequestItemFile'
        }
      })
      return { data: newMappedData }
    }
  }
}
