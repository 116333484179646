
export default {
  name: 'BuilderContentHtml',
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  }
}
