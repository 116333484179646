
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderContentClientGrid',
  components: {
    LazyHydrate,
    UiImageGrid: () => import('@/components/ui/grids/ImageGrid')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  computed: {
    images () {
      return this.section.clients.filter(item => {
        return (this.section?.styles?.white_background && item.client?.logo_transparent_black)
          || (!this.section?.styles?.white_background && item.client?.logo_transparent_white)
      }).map(item => {
        const logo = this.section?.styles?.white_background ? item.client.logo_transparent_black : item.client.logo_transparent_white
        return {...logo, ...{ alternativeText: item.client.name }}
      })
    }
  }
}
