
import MasonryWall from '@yeger/vue2-masonry-wall'
import UiCaseStudyListItem from '@/components/ui/case-study/List/Item'

export default {
  name: 'UiCaseStudyList',
  components: {
    UiCaseStudyListItem,
    MasonryWall
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    imageLazyLoading: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      width: 390,
      gap: 70
    }
  },
  computed: {
    columnWidth () {
      return this.width / 2
    },
    filteredItems () {
      return this.items.filter(item => item.listing_image)
    }
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },
  mounted () {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    preload (index) {
      return !!(this.imageLazyLoading?.preload
        && (
          !this.imageLazyLoading?.preload_count || this.imageLazyLoading?.preload_count >= index+1
        ))
    },
    onResize() {
      if (window.innerWidth >= 1400) {
        this.width = 500;
        this.gap = 70
      } else if (window.innerWidth >= 1200) {
        this.width = 500;
        this.gap = 60
      } else if (window.innerWidth >= 992) {
        this.width = 400;
        this.gap = 40
      } else if (window.innerWidth >= 768) {
        this.width = 300;
        this.gap = 30
      } else {
        this.width = 200;
        this.gap = 20
      }
      this.$forceUpdate()
    }
  }
}
