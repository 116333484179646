
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderBlogFeaturedPosts',
  components: {
    LazyHydrate,
    UiFeaturedBlogPosts: () => import('@/components/ui/blog/FeaturedBlogPosts')
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    posts () {
      return this.section.posts.map(post => post.blog_post)
    }
  }
}
