
import { mapGetters } from 'vuex'

export default {
  name: 'BuilderHeroBanner',
  computed: {
    ...mapGetters({
      isMenuOpen: 'ui/isMenuOpen',
      isMenuUnpinned: 'ui/isMenuUnpinned'
    })
  }
}
