

export default {
  name: 'BuilderTextAndImage',
  components: {
    UiTextAndImage: () => import('@/components/ui/sections/TextAndImage')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
