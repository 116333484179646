
export default {
  name: 'UiFactsBox',
  props: {
    facts: {
      type: Array,
      required: true
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (!this.styles?.no_container) {
        classes.push('row')
      }
      if (this?.styles?.row_classes) {
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    cleanContent () {
      return unescape(this.content)
    }
  }
}
