import { render, staticRenderFns } from "./PageViewWithTags.vue?vue&type=template&id=c2fbf028&scoped=true"
import script from "./PageViewWithTags.vue?vue&type=script&lang=js"
export * from "./PageViewWithTags.vue?vue&type=script&lang=js"
import style0 from "./PageViewWithTags.vue?vue&type=style&index=0&id=c2fbf028&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2fbf028",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiCaseStudyTagList: require('/home/node/app/components/ui/case-study/Tag/List.vue').default,UiCaseStudyList: require('/home/node/app/components/ui/case-study/List.vue').default})
