

export default {
  name: 'BuilderPageHeader',
  components: {
    UiHeader: () => import('@/components/ui/sections/Header')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  }
}
