
export default {
  name: 'UiButton',
  props: {
    button: {
      type: Object,
      required: true
    }
  }
}
