
import CaseStudyTag from '@/mixins/case-study-tag'

export default {
  name: 'UiCaseStudyTagListItem',
  mixins: [
    CaseStudyTag
  ],
  props: {
    tag: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    selectedTags: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  }
}
