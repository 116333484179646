
import Wysiwyg from '@/mixins/wysiwyg'
import Image from '@/mixins/image'

export default {
  name: 'BuilderContentTextAndImageList',
  mixins: [
    Wysiwyg,
    Image
  ],
  props: {
    styles: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    image: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    footer: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    hasBackgroundStyles () {
      return this.section?.background?.color || this.section?.background?.image?.url
    }
  },
  methods: {
    getBackgroundStyles () {
      const styles = []
      if (this.section?.background_image?.url) {
        const resizedUrl = this.getResizedImage(this.section.background_image.url, {
          format: 'webp',
          fit: 'cover',
          quality: '50',
        })
        styles.push('background-image:url("' + resizedUrl.url + '");')
      }
      return styles.join(' ')
    }
  }
}
