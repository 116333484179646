
import { Swiper, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([ Navigation ])

export default {
  name: 'UiGallerySwiper',
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    images: {
      type: Array,
      required: true
    },
    autoBackgroundColor: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      slider: null,
      sliderOptions: {
        slidesPerView: 3,
        spaceBetween: 80,
        speed: 1e3,
        loop: !0,
        preloadImages: !1,
        lazy: !0,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 60
          }
        },
        on: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          touchStart (swiper, event) {
            event?.target?.classList?.add('hold')
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          touchEnd (swiper, event) {
            event?.target?.classList?.remove('hold')
          },
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.slider = new Swiper(this.$refs.swiper, this.sliderOptions)
      // this.$emit('mounted', this.slider)
    })
  },
  beforeDestroy() {
    this.slider?.autoplay?.stop()
    this.slider?.destroy()
  }
}
