

export default {
  name: 'LiveChat',
  data () {
    return {
      showPlaceholder: true,
      expanded: false
    }
  },
  head () {
    return {
      script: [
        { children: 'window.olark = []' },
        {
          hid: 'olark',
          async: true,
          type: 'text/partytown',
          innerHTML: ";(function(o,l,a,r,k,y){if(o.olark)return; r=\"script\";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src=\"//\"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y(\"extend\",i,j)}; y.identify=function(i){y(\"identify\",k.i=i)}; y.configure=function(i,j){y(\"configure\",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,\"static.olark.com/jsclient/loader.js\");\n" +
            "olark.identify('4953-446-10-2073');",
          // Changed after script load
          callback: () => {
              window.olark('api.box.onExpand', () => {
                this.expanded = true
              })
              window.olark('api.box.onShrink', () => {
                this.expanded = false
              })
          }
        }
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'olark': ['innerHTML'], // Matches the ID I set in the script.
      }
    }
  },
  computed: {
    isChatOpen () {
      return window.olark
    },
  },
  mounted () {
    // console.debug('Olark: Immediate load required: ' + this.olarkRequireImmediateLoad())
    if (this.olarkRequireImmediateLoad()) {
      this.loadScript()
    } else {
      this.showPlaceholder = true
    }
  },
  methods: {
    openChat () {
      this.loadScript()
      window.olark('api.box.expand')
    },
    toggleChat () {
      this.expanded ? window.olark('api.box.shrink') : window.olark('api.box.expand')
    },
    loadScript () {
      if (this.isBrowser() && this.canShow()) {
        // console.debug('Olark: Checking if script exists')
        // console.debug(this.olarkSnippetExists())
        if (!this.olarkSnippetExists()) {
          // const script = document.createElement('script')
          // script.setAttribute('id', 'olark-vue-snippet')
          // // script.setAttribute('type', 'text/javascript')
          // script.setAttribute('async', 'true')
          // script.setAttribute('type', 'text/partytown')
          // script.onload = () => { window.olarkVue() }
          // script.innerHTML = `
          //   window.olarkVue = function() {
          //   console.log('Olark loaded');
          //     ;(function(o,l,a,r,k,y){if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y("extend",i,j)}; y.identify=function(i){y("identify",k.i=i)}; y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js");
          //     olark.identify('4953-446-10-2073');
          //     window.olarkVue.olarkWasLaunched = true
          //     olark('api.box.shrink')
          //   };
          //   window.olarkVue()
          //   window.olark('api.box.onExpand', () => {
          //     this.expanded = true
          //   })
          //   window.olark('api.box.onShrink', () => {
          //     this.expanded = false
          //   })
          //   `
          //
          // // console.debug('Olark: Creating script')
          // document.body.appendChild(script)
          // window.dispatchEvent(new CustomEvent('ptupdate'));

          this.setOlarkRequireImmediateLoad()
          this.showPlaceholder = false
        }
      }
    },
    isBrowser () {
      return typeof window !== 'undefined' && typeof document !== 'undefined'
    },
    canShow () {
      return (parseInt(localStorage.getItem('olark-can-show')) === 1)
    },
    olarkSnippetExists () {
      return (this.isBrowser() && 'olarkVue' in window)
    },
    olarkExists () {
      return this.isBrowser() && !!window.olark
    },
    setOlarkRequireImmediateLoad () {
      localStorage.setItem('olark-immediate-load', 1)
      // console.debug('Olark: Set immediate load cookie')
    },
    olarkRequireImmediateLoad () {
      return parseInt(localStorage.getItem('olark-immediate-load')) === 1
    }
  }
}
