
import { Swiper, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import Wysiwyg from '~/mixins/wysiwyg'
Swiper.use([ Navigation, Pagination ])

export default {
  name: 'UiCompareSwiper',
  mixins: [Wysiwyg],
  props: {
    items: {
      type: Array,
      required: true
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  data () {
    return {
      slider: null,
      sliderOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        speed: 1e3,
        loop: !1,
        preloadImages: !1,
        lazy: !0,
        initialSlide: 2,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2.25,
            initialSlide: 1,
          },
          768: {
            slidesPerView: 2.25,
            initialSlide: 1,
          },
          992: {
            slidesPerView: 3,
            initialSlide: 1,
          },
          1200: {
            slidesPerView: 3,
            initialSlide: 0,
          },
          1400: {
            slidesPerView: 4,
            initialSlide: 0,
          }
        },
        on: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          touchStart (swiper, event) {
            event?.target?.classList?.add('hold')
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          touchEnd (swiper, event) {
            event?.target?.classList?.remove('hold')
          },
        }
      }
    }
  },
  computed: {
    classes () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.slider = new Swiper(this.$refs.swiper, this.sliderOptions)
      // this.$emit('mounted', this.slider)
    })
  },
  beforeDestroy() {
    this.slider?.autoplay?.stop()
    this.slider?.destroy()
  }
}
