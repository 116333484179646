
import UiHeader from '@/components/ui/sections/Header'
import UiCaseStudyList from '@/components/ui/case-study/List'
import UiCaseStudyTagList from '@/components/ui/case-study/Tag/List'

export default {
  name: 'CaseStudyIndex',
  components: {
    UiHeader,
    UiCaseStudyList,
    UiCaseStudyTagList
  },
  props: {
    tags: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    selectedTags: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    items: {
      type: Array,
      required: true
    }
  }
}
