
export default {
  name: 'BuilderTextAndIconGrid',
  components: {
    UiTextAndIconGrid: () => import('@/components/ui/sections/TextAndIconGrid')
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
