
import { Swiper, Navigation, Pagination } from 'swiper'
import padStart from 'lodash/padStart'
import 'swiper/swiper-bundle.css'
import Wysiwyg from '@/mixins/wysiwyg'
Swiper.use([Navigation, Pagination])

export default {
  name: 'BuilderCaseStudySlider',
  mixins: [
    Wysiwyg
  ],
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    slides: {
      type: Array,
      required: true
    },
    imageField: {
      type: String,
      default: 'listing_image'
    },
    height: {
      type: Number,
      default: 800
    },
    width: {
      type: Number,
      default: 1260
    },
  },
  data() {
    return {
      slider: null,
      sliderOptions: {
        slidesPerView: 2,
        spaceBetween: 0,
        speed: 600,
        loop: !0,
        centeredSlides: !0,
        preloadImages: !1,
        lazy: !0,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 0
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        },
        pagination: {
          el: '.portfolio-carousel-fluid .swiper-pagination',
          type: 'progressbar'
        },
        navigation: {
          nextEl: '.portfolio-carousel-fluid .swiper-button.next',
          prevEl: '.portfolio-carousel-fluid .swiper-button.prev'
        }
      }
    }
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    totalSlides() {
      return padStart(this.slides.length, 2, '0')
    },
    imageHeightOverlay () {
      return Math.round(500 * (this.height / this.width))
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.slider = new Swiper(this.$refs.swiper, this.sliderOptions)
      // this.$emit('mounted', this.slider)
    })
  },
  beforeDestroy() {
    this.slider?.autoplay?.stop()
    this.slider?.destroy()
  },
  methods: {
    caseStudyUrl (slug) {
      return '/case-studies/' + slug
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageHeight (image) {
      return this.height
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageWidth (image) {
      return this.width
    },
    imageAspectRatio () {
      return this.height / this.width
    }
  }
}
