import { render, staticRenderFns } from "./ColourPalette.vue?vue&type=template&id=1aeed0a8"
import script from "./ColourPalette.vue?vue&type=script&lang=js"
export * from "./ColourPalette.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSectionsColourPalette: require('/home/node/app/components/ui/sections/ColourPalette.vue').default})
