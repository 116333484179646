

export default {
  name: 'UiFullWidthImage',
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    image: {
      type: Object,
      required: true
    },
    imageMobile: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    loading: {
      type: String,
      default: 'lazy'
    },
    preload: {
      type: Boolean,
      default: null
    },
    hideOnMobile: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 660
    },
    width: {
      type: Number,
      default: 1290
    },
    heightMobile: {
      type: Number,
      default: 560
    },
    widthMobile: {
      type: Number,
      default: 560
    },
    quality: {
      type: Number,
      default: 70
    },
    overlapTop: {
      type: Boolean,
      default: false
    },
    overlapBottom: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      if (this?.overlapTop) {
        classes.push('overlap-top')
      }
      if (this?.overlapBottom) {
        classes.push('overlap-bottom')
      }
      if (!this?.preload) {
        classes.push('has-anim fade')
      }
      if (this.hideOnMobile) {
        classes.push('d-none d-md-block')
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    imageHeight () {
      let height = this.height
      if (!this.isImagePortrait) {
        height = this.width / (this.image.width / this.image.height)
      }
      // console.log({
      //   imageWidth: this.image.width,
      //   imageHeight: this.image.height,
      //   aspectRatio: this.image.width / this.image.height,
      //   height,
      // })
      return height
    },
    imageWidth() {
      // if (this.isImagePortrait) {
      //   width.push(this.height * (this.image.width / this.image.height))
      // }
      return this.width
    },
    isImagePortrait () {
      return this.image.height >= this.image.width
    },
    imageMobileHeight () {
      const height = [this.heightMobile]
      if (!this.isMobileImagePortrait) {
        height.push(this.imageMobile.height * (this.heightMobile / this.imageMobile.width))
      }
      return Math.round(Math.min(...height))
    },
    imageMobileWidth() {
      const width = [this.widthMobile]
      if (this.isMobileImagePortrait) {
        width.push(this.imageMobile.width * (this.widthMobile / this.imageMobile.height))
      }
      return Math.round(Math.min(...width))
    },
    isMobileImagePortrait () {
      return this.imageMobile.height >= this.imageMobile.width
    }
  }
}
