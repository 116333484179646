
import unescape from 'lodash/unescape'

export default {
  name: 'UiImageGrid',
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    images: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    pretitle: {
      type: String,
      required: false,
      default: null
    },
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    sizes () {
      if (this.images.length === 4) {
        return 'xs:250px'
      }
      return 'xs:200px'
    },
    width () {
      return (this.images.length === 4) ? 250 : 200
    },
    height () {
      return (this.images.length === 4) ? 200 : 150
    }
  },
  methods: {
    stripHTML(content) {
      return unescape(content)
        .replace(/(<([^>]+)>)/gi, '')
        .trim()
    }
  }
}
