
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'PageBuilderCompareSlider',
  components: {
    LazyHydrate,
    UiImageCompare: () => import('@/components/ui/sections/ImageCompare'),
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  },
  computed: {
    items () {
      return this.section.slides
    }
  }
}
