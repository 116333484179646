
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderLargeCallToAction',
  components: {
    LazyHydrate,
    UiLargeCallToAction: () => import('@/components/ui/sections/LargeCallToAction')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
}
