
import applicationMutation from '@/graphql/query/applicationMutation.gql'
import UiHeader from '@/components/ui/sections/Header'
import Animation from '@/mixins/animation'
import PageBuilderComponentLoader from '@/mixins/pageBuilderComponentLoader'
import Wysiwyg from '@/mixins/wysiwyg'
import UiForm from '@/components/ui/form/Form'

export default {
  name: 'CareerView',
  components: {
    UiHeader,
    UiForm
  },
  mixins: [
    Animation,
    PageBuilderComponentLoader,
    Wysiwyg
  ],
  async asyncData(context) {
    const { slug } = context.params
    const { useContent } = await import('@/datalayer/careers')
    const { career, meta, jsonld } = await useContent(context, slug)
    return { career, meta, jsonld }
  },
  data () {
    return {
      fields: [
        {
          name: 'name',
          type: 'text',
          required: true,
          placeholder: 'Name'
        },
        {
          name: 'email',
          placeholder: 'Email',
          type: 'email',
          required: true
        },
        {
          name: 'phone',
          placeholder: 'Phone Number',
          type: 'text',
        },
        {
          name: 'message',
          placeholder: 'Cover Letter',
          type: 'textarea',
          fullWidth: true,
          required: true
        },
        {
          name: 'file',
          type: 'file',
          multiple: true,
          fullWidth: true,
          accept: '.jpg, .jpeg, .png, .gif, .tiff, .svg, .webp, .avif, .mp4, .mov, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .fig, .ai, .xd, .sketch'
        },
        {
          name: 'consent',
          type: 'checkbox',
          required: true,
          value: false,
          fullWidth: true,
          placeholder: 'By using this form you agree with the storage and handling of your data by this website.'
        },
      ],
      mutation: applicationMutation
    }
  },
  head () {
    return this.meta
  },
  jsonld () {
    return this.jsonld
  }
}
