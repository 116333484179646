

export default {
  name: 'BuilderContentImage',
  components: {
    UiImage: () => import('@/components/ui/sections/Image')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
