
// @todo implement floating labels for better usability https://getbootstrap.com/docs/5.0/forms/floating-labels/

import FormMixin from '@/mixins/form'

export default {
  name: 'UiForm',
  components: {
    UiFormInputCheckbox: () => import('@/components/ui/form/input/Checkbox'),
    UiFormInputEmail: () => import('@/components/ui/form/input/Email'),
    UiFormInputFile: () => import('@/components/ui/form/input/File'),
    UiFormInputHidden: () => import('@/components/ui/form/input/Hidden'),
    UiFormInputSelect: () => import('@/components/ui/form/input/Select'),
    UiFormInputText: () => import('@/components/ui/form/input/Text'),
    UiFormInputTextarea: () => import('@/components/ui/form/input/Textarea'),
    UiFormInputPhone: () => import('@/components/ui/form/input/Phone'),
    UiFormInputUrl: () => import('@/components/ui/form/input/Url'),
  },
  mixins: [
    FormMixin
  ],
  props: {
    mutation: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    additionalFormData: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    formDataTransformer: {
      type: Function,
      required: false,
      default: (data) => data
    },
    fileIdsByFieldTransformer: {
      type: Function,
      required: false,
      default: (data) => data
    },
    whiteBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
