
import CaseStudy from '@/mixins/case-study'
import Wysiwyg from '@/mixins/wysiwyg'

export default {
  name: 'UiCaseStudyListItem',
  mixins: [
    CaseStudy,
    Wysiwyg
  ],
  props: {
    caseStudy: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    preload: {
      type: Boolean,
      default: false,
      required:false
    }
  }
}
