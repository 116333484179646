
import Post from '@/mixins/blog/post'

export default {
  name: 'UiFeaturedBlogPostsItem',
  mixins: [
    Post
  ],
  props: {
    post: {
      type: Object,
      required: true
    },
    horizontalCard: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
