
import PageBuilderComponentLoader from '@/mixins/pageBuilderComponentLoader'
import Animation from '@/mixins/animation'

export default {
  name: 'SEOPage',
  mixins: [
    Animation,
    PageBuilderComponentLoader
  ],
  async asyncData(context) {
    const { slug } = context.params
    const { useContent } = await import('@/datalayer/cms/page')
    const { page, meta, jsonld } = await useContent(context, slug)
    return { page, meta, jsonld }
  },
  data () {
    return {
      loading: 0
    }
  },
  head () {
    return this.meta
  },
  jsonld () {
    return this.jsonld
  }
}
