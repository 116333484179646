
export default {
  name: 'UiFormInputHidden',
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  },
  emits: ['update:value']
}
