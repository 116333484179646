
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderQuoteSlider',
  components: {
    LazyHydrate,
    UiQuoteSwiper: () => import('@/components/ui/swipers/QuoteSwiper'),
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  }
}
