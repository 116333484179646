
import unescape from 'lodash/unescape'

export default {
  name: 'UiTextAndImage',
  components: {
    UiButton: () => import('@/components/ui/elements/Button')
  },
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    content: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    image: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    cleanContent () {
      return unescape(this.content)
    },
    rowClassesExtended () {
      const classes = this.rowClasses.split(' ')
      if (this.number %2 === 1) {
        classes.push('flex-row-reverse')
      }
      return classes
    },
    imageHeight () {
      const height = [500]
      if (!this.isImagePortrait) {
        height.push(this.image.height * (500 / this.image.width))
      }
      return Math.round(Math.min(...height))
    },
    imageWidth() {
      const width = [500]
      if (this.isImagePortrait) {
        width.push(this.image.width * (500 / this.image.height))
      }
      return Math.round(Math.min(...width))
    },
    isImagePortrait () {
      return this.image.height >= this.image.width
    }
  }
}
