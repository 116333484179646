
export default {
  name: 'UiFormInputSelect',
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  },
  emits: ['update:value'],
  data () {
    return {
      loaded: false,
      valid: true,
    }
  },
  mounted () {
    this.loaded = true
  },
  methods: {
    selected (option, options, index, defaultValue) {
      const selectedOption = options.find(o => o?.select)
      if (selectedOption?.name && selectedOption.name === option.name) {
        return true
      }
      if (option.value === defaultValue) {
        return true
      }
      return selectedOption?.name || index === 0 && !this.field.placeholder
    }
  }
}
