
import Post from '@/mixins/blog/post'
import UiHeader from '@/components/ui/sections/Header'
import UiFullWidthImage from '@/components/ui/sections/FullWidthImage'
import Animation from '@/mixins/animation'
import PageBuilderComponentLoader from '@/mixins/pageBuilderComponentLoader'
import Wysiwyg from '@/mixins/wysiwyg'

export default {
  name: 'BlogView',
  components: {
    UiHeader,
    UiFullWidthImage
  },
  mixins: [
    Animation,
    Post,
    PageBuilderComponentLoader,
    Wysiwyg
  ],
  async asyncData(context) {
    const { slug } = context.params
    const { useContent } = await import('@/datalayer/blog/post')
    const { post, meta, jsonld } = await useContent(context, slug)
    return { post, meta, jsonld }
  },
  head () {
    return this.meta
  },
  jsonld () {
    return this.jsonld
  }
}
