
import { Swiper, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import padStart from 'lodash/padStart'
import truncate from 'lodash/truncate'
import Wysiwyg from '@/mixins/wysiwyg'

Swiper.use([ Navigation, Pagination ])

export default {
  name: 'UiQuoteSwiper',
  mixins: [
    Wysiwyg
  ],
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    quotes: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      slider: null,
      sliderIndex: '01',
      sliderOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 600,
        loop: !1
      }
    }
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    totalQuotes () {
      return padStart(this.quotes.length, 2, '0')
    },
    currentSlide () {
      if (this.slider?.realIndex) {
        return padStart(this.slider.realIndex, 2, '0')
      }
      return '01'
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.quotes.length > 1) {
        this.slider = new Swiper(this.$refs.swiper, this.sliderOptions)
        // this.$emit('mounted', this.slider)
      }
    })
  },
  beforeDestroy() {
    this.slider?.autoplay?.stop()
    this.slider?.destroy()
  },
  methods: {
    truncate (content, length) {
      return truncate(content, {length})
    }
  }
}
