
import ImageMixin from '@/mixins/image'

export default {
  name: 'UiFullWidthVideo',
  directives: { muted: true },
  mixins: [ImageMixin],
  props: {
    video: {
      type: Object,
      required: true
    },
    controls: {
      type: Boolean,
      default: true
    },
    aspectRatio: {
      type: String,
      default: "16:9"
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    muted: {
      type: Boolean,
      default: false
    },
    overlapTop: {
      type: Boolean,
      default: false
    },
    overlapBottom: {
      type: Boolean,
      default: false
    },
    poster: {
      type: Object,
      default: null
    },
    preload: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getPosterURL() {
      if (this?.poster?.url) {
        // Ensure that poster is the same size/aspect as the video
        return this.getResizedImage(this?.poster?.url, {
          format: 'webp',
          fit: 'cover',
          quality: '50',
          width: this.video?.width,
          height: this.video?.height
        })?.url
      }
      return null
    },
    sectionClasses() {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      if (this?.overlapTop) {
        classes.push('overlap-top')
      }
      if (this?.overlapBottom) {
        classes.push('overlap-bottom')
      }
      if (!this?.preload) {
        classes.push('has-anim fade')
      }
      if (this.hideOnMobile) {
        classes.push('d-none d-md-block')
      }
      return classes.join(' ')
    },
    containerClasses() {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses() {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    }
  }
}
