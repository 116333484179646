
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderCareersList',
  components: {
    LazyHydrate,
    UiHeader: () => import('@/components/ui/sections/Header'),
    UiCareersList: () => import('@/components/ui/careers/List')
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
