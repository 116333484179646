
import Wysiwyg from '@/mixins/wysiwyg'
import PageBuilderComponentLoader from '@/mixins/pageBuilderComponentLoader'
import Animation from '@/mixins/animation'

export default {
  name: 'FallBack',
  mixins: [
    Animation,
    Wysiwyg,
    PageBuilderComponentLoader
  ],
  asyncData ({ error, res }) {
    res?.setHeader('Cache-Control', 'public, no-cache, max-age=0, s-maxage=0')
    return error({ statusCode: 404, message: 'Page not found' })
  },
  head () {
    const title = (this.page?.meta?.page_title ?? this.page.title).replace(/(<([^>]+)>)/gi, '').trim()
    const description = ((this.page.meta && this.page.meta.meta_description) ? this.page.meta.meta_description : this.page.content)
      .replace(/(<([^>]+)>)/gi, '')
      .trim()
      .substring(0, 155)
    const keywords = ((this.page.meta && this.page.meta.meta_keywords) ? this.page.meta.meta_keywords : '').replace(/(<([^>]+)>)/gi, "")
    let robots = 'all'
    if (this.page.meta && this.page.meta.robots && this.page.meta.robots.length) {
      robots = this.page.meta.robots.map(robot => robot.robots).join(',')
    }
    return {
      title,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: title
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: keywords
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        },
        { hid: 'robots', name: 'robots', content: robots }
      ]
    }
  }
}
