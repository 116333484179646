
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderCaseStudySlider',
  components: {
    LazyHydrate,
    UiCaseStudySwiper: () => import('@/components/ui/swipers/CaseStudySwiper')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      clients: []
    }
  },
  async fetch () {
    if (!this.clients.length) {
      const { useList } = await import('@/datalayer/clients')
      const clientIds = []
      for (let i = 0; i < this.section.slides.length; i++) {
        clientIds.push(this.section.slides[i].slide.client)
      }
      this.clients = await useList(this, this.section.slides.length, 1, clientIds)
    }
  },
  computed: {
    slidesWithImages () {
      return this.section.slides
        .filter(item => item?.slide?.listing_image?.url)
        .map(item => item.slide)
        .map(item => {
          const client = this.clients.find(client => item.client === client.id)
          return {
            ...item,
            ...{ client }
          }
        })
    }
  }
}
