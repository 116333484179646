
import Wysiwyg from '@/mixins/wysiwyg'

export default {
  name: 'UiTextAndIconGrid',
  mixins: [
    Wysiwyg
  ],
  props: {
    button: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    items: {
      type: Array,
      required: true
    }
  }
}
