
export default {
  name: 'UiFormInputEmail',
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: null
    }
  },
  emits: ['update:value'],
  data () {
    return {
      loaded: false,
      valid: true,
    }
  },
  mounted () {
    this.loaded = true
  }
}
