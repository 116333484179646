
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderTextSection',
  components: {
    LazyHydrate,
    UiTextSection: () => import('@/components/ui/sections/TextSection')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    }
  }
}
