
import { ResizeSensor } from 'css-element-queries';

export default {
  name: 'UiSectionsImageCompare',
  props: {
    leftImage: {
      type: String,
      required: true
    },
    leftImageAlt: {
      type: String,
      default: null,
    },
    leftLabel: {
      type: String,
      default: '',
    },
    leftImageHeight: {
      type: Number,
      default: null,
    },
    leftImageWidth: {
      type: Number,
      default: null,
    },
    // under image
    rightImage: {
      type: String,
      required: true
    },
    rightImageAlt: {
      type: String,
      default: null,
    },
    rightLabel: {
      type: String,
      default: '',
    },
    rightImageHeight: {
      type: Number,
      default: null,
    },
    rightImageWidth: {
      type: Number,
      default: null,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    handleSize: {
      type: Number,
      default: 40,
    },
    sliderLineWidth: {
      type: Number,
      default: 2,
    },
    sliderPositionPercentage: {
      type: Number,
      default: 0.5,
    },
  },
  data() {
    return {
      // slider position percentage(0 to 1)
      positionPct: this.sliderPositionPercentage || 0.5,
      imageWidth: 0,
    };
  },
  computed: {
    // eslint-disable
    leftImageStyle() {
      return {
        clip: `rect(auto, ${this.imageWidth * this.positionPct}px, auto, auto)`,
      };
    },
    rightLabelStyle() {
      const cutLeft = Math.max(
        0,
        this.rightLabelWidth + this.imageWidth * (this.positionPct - 1),
      );
      return {
        clip: `rect(auto, auto, auto, ${cutLeft}px)`,
      };
    },
    sliderStyle() {
      return {
        cursor: !this.hover && 'ew-resize',
        left: this.imageWidth * this.positionPct - this.handleSize / 2 + 'px',
        width: `${this.handleSize}px`,
      };
    },
    sliderLineStyle() {
      return { width: `${this.sliderLineWidth}px` };
    },
    sliderHandleStyle() {
      return {
        border: `${this.sliderLineWidth}px solid white`,
        height: `${this.handleSize}px`,
        width: `${this.handleSize}px`,
      };
    },
    sliderLeftArrowStyle() {
      return {
        border: `inset ${this.handleSize * 0.15}px rgba(0,0,0,0)`,
        borderRight: `${this.handleSize * 0.15}px solid white`,
        marginLeft: `-${this.handleSize * 0.25}px`, // for IE11
        marginRight: `${this.handleSize * 0.25}px`,
      };
    },
    sliderRightArrowStyle() {
      return {
        border: `inset ${this.handleSize * 0.15}px rgba(0,0,0,0)`,
        borderLeft: `${this.handleSize * 0.15}px solid white`,
        marginRight: `-${this.handleSize * 0.25}px`, // for IE11
      };
    },
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      classes.push('row')
      if (this?.styles?.row_classes) {
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    cleanText () {
      return unescape(this.text)
    },
    cleanHoverText () {
      return unescape(this.hover_text)
    }
  },
  mounted() {
    // get and set `imageWidth` when the container size changed
    // (including first time rendering)
    //
    // eslint-disable-next-line
    new ResizeSensor(this.$refs.containerRef, () => {
      this.getAndSetImageWidth();
    });
    const containerElement = this.$refs.containerRef;

    // for mobile
    containerElement.addEventListener('touchstart', this.startSliding);
    window.addEventListener('touchend', this.finishSliding);

    // for desktop
    if (this.hover) {
      containerElement.addEventListener('mouseenter', this.startSliding);
      containerElement.addEventListener('mouseleave', this.finishSliding);
    } else {
      containerElement.addEventListener('mousedown', this.startSliding);
      window.addEventListener('mouseup', this.finishSliding);
    }
  },
  beforeDestroy() {
    this.finishSliding();
    window.removeEventListener('mouseup', this.finishSliding);
    window.removeEventListener('touchend', this.finishSliding);
  },
  methods: {
    getAndSetImageWidth() {
      this.imageWidth = this.$refs.rightImageRef.$vnode.elm.getBoundingClientRect().width;
      this.rightLabelWidth = this.$refs.rightLabelRef.getBoundingClientRect().width;
    },
    startSliding(e) {
      // Prevent default behavior other than mobile scrolling
      if (!('touches' in e)) {
        e.preventDefault();
      }

      // Slide the image even if you just click or tap (not drag)
      this.updateSliderPosition(e);

      window.addEventListener('mousemove', this.updateSliderPosition);
      window.addEventListener('touchmove', this.updateSliderPosition);
    },
    finishSliding() {
      window.removeEventListener('mousemove', this.updateSliderPosition);
      window.removeEventListener('touchmove', this.updateSliderPosition);
    },
    updateSliderPosition(event) {
      const e = event || window.event;

      // Calc Cursor Position from the left edge of the viewport
      const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX;

      // Calc Cursor Position from the left edge of the window (consider any page scrolling)
      const cursorXfromWindow = cursorXfromViewport - window.pageXOffset;

      // Calc Cursor Position from the left edge of the image
      const imagePosition = this.$refs.rightImageRef.$vnode.elm.getBoundingClientRect();
      let pos = cursorXfromWindow - imagePosition.left;

      // Set minimum and maximum values to prevent the slider from overflowing
      const minPos = this.sliderLineWidth / 2;
      const maxPos = this.imageWidth - this.sliderLineWidth / 2;

      if (pos < minPos) pos = minPos;
      if (pos > maxPos) pos = maxPos;

      this.positionPct = pos / this.imageWidth;
    },
  }
}
