

export default {
  name: 'UiCareersList',
  fetchOnServer: true,
  props: {
    sort: {
      type: String,
      required: false,
      default: 'published_at:desc'
    },
  },
  data () {
    return {
      careers: []
    }
  },
  async fetch () {
    const { useList } = await import('@/datalayer/careers')
    this.careers = await useList(this)
  },
  computed: {
    groupedByDepartment () {
      const grouped = {}
      const everythingElse = []
      this.careers.forEach(career => {
        if (career?.department?.name) {
          if (!grouped[career.department.name]?.careers) {
            grouped[career.department.name] = { careers: [], name: career.department.name, id: career?.department?.id }
          }
          grouped[career.department.name].careers.push(career)
        } else {
          everythingElse.push(career)
        }
      })
      if (everythingElse.length) {
        grouped['Everything else'] = { careers: everythingElse, name: 'Everything else' }
      }
      return grouped
    }
  }
}
