
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderProcessGrid',
  components: {
    LazyHydrate,
    UiAnimatedTextGrid: () => import('@/components/ui/grids/AnimatedTextGrid')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
