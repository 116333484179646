
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'BuilderButtonCallToAction',
  components: {
    LazyHydrate,
    UiButton: () => import('@/components/ui/sections/Button')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
