
import {BBadge} from 'bootstrap-vue/esm/components/badge'
import {BFormFile} from 'bootstrap-vue/esm/components/form-file'

export default {
  name: 'UiFormInputFile',
  components: {
    BFormFile,
    BBadge
  },
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      files: [],
      uploading: false,
      uploadingProgress: 0,
      errors: [],
      loaded: false,
      accept: this.field?.accept || null
    }
  },
  computed: {
    fileState() {
      if (this.files.length === 0 && !this.field?.required) {
        return null
      }
      return Boolean(this.files.length) && !this.errors.length
    }
  },
  watch: {
    files: {
      handler() {
        this.$emit('files-added', { field: this.field, files: Array.from(this.files) })
      },
      deep: true
    }
  },
  mounted () {
    this.loaded = true
  }
}
