
import UiFeaturedBlogPostsItem from '@/components/ui/blog/FeaturedBlogPosts/Item'

export default {
  name: 'UiFeaturedBlogPosts',
  components: {
    UiFeaturedBlogPostsItem
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    pretitle: {
      type: String,
      required: false,
      default: null
    },
    posts: {
      type: Array,
      required: true
    },
    backgroundWhite: {
      type: Boolean,
      default: false
    }
  }
}
