
export default {
  name: 'UiImage',
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    image: {
      type: Object,
      required: true
    },
    loading: {
      type: String,
      default: 'lazy'
    },
    preload: {
      type: Boolean,
      default: null
    },
    height: {
      type: Number,
      default: 660
    },
    width: {
      type: Number,
      default: 1270
    },
    quality: {
      type: Number,
      default: 70
    },
    sizes: {
      type: String,
      default: 'xs:280px sm:560px md:1016px lg:1270px'
    }
  },
  computed: {
    imageUrl () {
      return this.image.url.endsWith('svg') ? '/resize' + this.image.url : this.image.url
    }
  }
}
