
import UiHeader from '@/components/ui/sections/Header'
import UiForm from '@/components/ui/form/Form'
import contactMutation from '@/graphql/query/contactMutation.gql'
import Animation from '@/mixins/animation'

export default {
  name: 'ContactIndex',
  components: {
    UiHeader,
    UiForm,
  },
  mixins: [
    Animation
  ],
  async asyncData(context) {
    const { useContent } = await import('@/datalayer/addresses')
    const addresses = await useContent(context)
    return {
      addresses
    }
  },
  data () {
    return {
      addresses: [],
      fields: [
        {
          name: 'name',
          type: 'text',
          required: true,
          placeholder: 'Name'
        },
        {
          name: 'title',
          placeholder: 'Title/Role',
          type: 'text'
        },
        {
          name: 'email',
          placeholder: 'Email',
          type: 'email',
          required: true
        },
        {
          name: 'phone',
          placeholder: 'Phone Number',
          type: 'text',
        },
        {
          name: 'company',
          placeholder: 'Company/Organisation',
          type: 'text',
        },
        {
          name: 'message',
          placeholder: 'Message',
          type: 'textarea',
          fullWidth: true,
          required: true
        },
        {
          name: 'file',
          type: 'file',
          multiple: true,
          fullWidth: true,
          accept: '.jpg, .jpeg, .png, .gif, .tiff, .svg, .webp, .avif, .mp4, .mov, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .fig, .ai, .xd, .sketch'
        },
        {
          name: 'consent',
          type: 'checkbox',
          required: true,
          value: false,
          fullWidth: true,
          placeholder: 'By using this form you agree with the storage and handling of your data by this website.'
        },
      ],
      mutation: contactMutation
    }
  },
  head () {
    const title = 'Contact Us'
    const description = 'Test'
    const keywords = 'test'
    const robots = 'all'
    return {
      title,
      meta: [
        {
          hid: 'og:title',
          name: 'og:title',
          content: title
        },
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: keywords
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description
        },
        { hid: 'robots', name: 'robots', content: robots }
      ]
    }
  },
  jsonld () {
    const locationJson = []
    const contactPointJson = []
    if (this.addresses.length) {
      this.addresses.forEach(function(address) {
        locationJson.push({
          '@type': 'PostalAddress',
          name: address.title,
          streetAddress: address.street,
          addressLocality: address.city,
          postalCode: address.postcode,
          addressCountry: address.country
        })
        contactPointJson.push({
          '@type': 'ContactPoint',
          name: address.title,
          telephone: address.telephone,
          email: 'hello@ayko.com',
          contactType: 'Office, Sales',
          availableLanguage: {
            '@type': 'Language',
            name: 'English',
            alternateName: 'en'
          },
        })
      })
    }
    return {
      '@context': 'https://schema.org',
      '@type': 'ContactPage',
      name: 'Contact Us',
      description: 'We’d love to hear from you, get in touch with us.',
      author: {
        '@type': 'Organization',
        name: 'AYKO',
        legalName: 'Ayko Digital Limited',
        vatID: 'GB323473810',
        url: 'https://ayko.com',
        email: 'hello@ayko.com',
        telephone: contactPointJson?.[0]?.telephone || '',
        contactPoint: contactPointJson,
        location: locationJson,
        ethicsPolicy: 'https://ayko.com/position-statement'
      }
    }
  },
}
