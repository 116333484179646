
import LazyHydrate from 'vue-lazy-hydration'
export default {
  name: 'BuilderContentContent',
  components: {
    LazyHydrate,
    UiContent: () => import('@/components/ui/sections/Content')
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  }
}
