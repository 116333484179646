
import unescape from 'lodash/unescape'

export default {
  name: 'UiHeader',
  props: {
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
    h1: {
      type: String,
      required: true,
    },
    h1Tag: {
      type: String,
      default: 'h1'
    },
    h2: {
      type: String,
      required: false,
      default: null
    },
    h2Tag: {
      type: String,
      default: 'h2'
    },
    p: {
      type: String,
      required: false,
      default: null
    },
    textCentered: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (this.styles?.no_section) {
        return classes
      }
      classes.push('section page-header')
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      return this?.styles?.no_container ? '' : 'container'
    },
    rowClasses () {
      const classes = []
      if (this?.styles?.row_classes) {
        classes.push('row')
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    h1WithoutPeriod () {
      return unescape(this.h1.replace(/[.!?]$/, ''))
    },
    h1Period () {
      const matches = this.h1.match(/[.!?]$/)
      return matches?.length ? matches[0] : ''
    }
  },
  methods: {
    unescape (content) {
      return unescape(content)
    }
  }
}
