
import Animation from '@/mixins/animation'

export default {
  name: 'UiSectionsColourPalette',
  mixins: [Animation],
  props: {
    colours: {
      type: Array,
      required: true
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
  computed: {
    sectionClasses () {
      const classes = []
      if (!this.styles?.no_section) {
        classes.push('section')
      }
      if (this.styles?.white_background) {
        classes.push('bg-white')
      }
      if (this.styles?.classes) {
        classes.push(this.styles?.classes)
      }
      return classes.join(' ')
    },
    containerClasses () {
      const classes = []
      classes.push('palette-count-' + this.colours.length)
      if (!this?.styles?.no_container) {
        classes.push('container')
      }
      return classes.join(' ')
    },
    rowClasses () {
      const classes = []
      classes.push('row')
      classes.push('justify-content-center')
      if (this?.styles?.row_classes) {
        classes.push(this.styles.row_classes)
      }
      return classes.join(' ')
    },
    cleanText () {
      return unescape(this.text)
    },
    cleanHoverText () {
      return unescape(this.hover_text)
    }
  }
}
