
export default {
  name: 'ScrollProgress',
  data() {
    return {
      progress: '0%'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateProgressIndicator)
  },
  destroyed () {
    window.removeEventListener('scroll', this.updateProgressIndicator)
  },
  methods: {
    updateProgressIndicator() {
      const path = this.$refs['scroll-progress-path']
      const pathLength = path.getTotalLength()
      // console.log({pathLength, path})
      path.style.transition = path.style.WebkitTransition = 'none'
      path.style.strokeDasharray = pathLength + ' ' + pathLength
      path.style.strokeDashoffset = pathLength
      path.getBoundingClientRect()
      path.style.transition = path.style.WebkitTransition = 'stroke-dashoffset 10ms linear'

      const { documentElement, body } = document
      const windowScroll = body.scrollTop || documentElement.scrollTop
      const height = documentElement.scrollHeight - documentElement.clientHeight
      this.progress = (windowScroll / height) * 100 + '%'
    }
  }
}
