
import Wysiwyg from '@/mixins/wysiwyg'
import Image from '@/mixins/image'

export default {
  name: 'BuilderContentTextAndImageList',
  components: {
    UiTextAndImageList: () => import('@/components/ui/sections/TextAndImageList')
  },
  mixins: [
    Wysiwyg,
    Image
  ],
  props: {
    section: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: false,
      default: null
    },
  },
}
