
import Animation from '@/mixins/animation'
import PageBuilderComponentLoader from '@/mixins/pageBuilderComponentLoader'

export default {
  name: 'CaseStudyView',
  components: {
    UiHeader: () => import('@/components/ui/sections/Header'),
    UiFullWidthImage: () => import('@/components/ui/sections/FullWidthImage'),
    UiFullWidthVideo: () => import('@/components/ui/sections/FullWidthVideo'),
    UiImage: () => import('@/components/ui/sections/Image')
  },
  mixins: [
    Animation,
    PageBuilderComponentLoader
  ],
  async asyncData(context) {
    const { slug } = context.params
    const { useContent } = await import('@/datalayer/casestudy')
    const { caseStudy, relatedCaseStudies, meta, jsonld } = await useContent(context, slug)
    return { caseStudy, relatedCaseStudies, meta, jsonld }
  },
  data () {
    return {
      loading: 0
    }
  },
  head () {
    return this.meta
  },
  jsonld () {
    return this.jsonld
  }
}
