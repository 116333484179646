
import UiCaseStudyTagListItem from '@/components/ui/case-study/Tag/List/Item'

export default {
  name: 'UiCaseStudyTagList',
  components: {
    UiCaseStudyTagListItem
  },
  props: {
    tags: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    selectedTags: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  }
}
